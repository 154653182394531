<template>
    <div class="site-link text-center">
        <a :href="site.link" class="link-general d-inline-block mb-1" target="_blank">
            <h3>{{ site.name }}</h3>
            <img :alt="site.name" :src="site.image" class="img-thumbnail">
        </a>
        <p>{{ site.description }}</p>
    </div>
</template>

<script>
export default {
  name: 'SiteLink',
  props: ['site'],
}
</script>

<style scoped>
.img-thumbnail {
    max-width: 100px;
}
</style>
