<template>
    <div class="sites">
        <HeaderPage :title="title"></HeaderPage>
        <main class="container-lg page-wrapper py-2">
            <div class="text-center m-5">
                <h2>Website is close service right now. <br>Enjoy other corners for relax:</h2>
            </div>
            <div class="row">
                <SiteLink v-for="site in sites" :key="site.link" :site="site" class="col-6 col-md-4"></SiteLink>
            </div>
        </main>
    </div>
</template>

<script>
import HeaderPage from '../components/HeaderPage'
import SiteLink from '../components/SiteLink'

export default {
  name: 'Sites',
  components: {SiteLink, HeaderPage},
  data () {
    return {
      title: 'Other corners for relax',
      sites: [
        {
          name: 'The joi Database',
          link: 'http://www.the-joi-database.com/',
          image: require(`@/assets/sites/the-joi-database-logo.png`),
          description: 'Desktop application and even more JOI videos.',
        },
        {
          name: 'Faproulette',
          link: 'https://faproulette.co',
          image: require(`@/assets/sites/faproulette-logo.png`),
          description: 'Fap roulettes.',
        },
        {
          name: 'Teases',
          link: 'https://teases.relaxcorner.moe/',
          image: require(`@/assets/sites/teases-logo.jpg`),
          description: 'Downloadable teases from milovana.',
        },
        {
          name: 'Milovana',
          link: 'https://milovana.com/',
          image: require(`@/assets/sites/milovana-logo.jpg`),
          description: 'More-less interactive games.',
        },
        {
          name: 'Japanese ASMR',
          link: 'https://japaneseasmr.com/',
          image: require(`@/assets/sites/japaneseasmr-logo.png`),
          description: 'Base of japanesee ASMR.',
        },
        {
          name: 'Hero',
          link: 'https://hero.relaxcorner.moe/',
          image: require(`@/assets/sites/hero-logo.jpg`),
          description: 'Hero slide show with marbles.',
        },
      ]
    }
  },
  computed: {}
}
</script>
